<template>
  <div class="mettings">
      <div class="row">
        <div class="col">
          <h2 class="my-2">
            <b-badge variant="primary" class="badge-top-section">{{ $t('My_Requests') }}</b-badge>
          </h2>
        </div>
      </div>
        <b-row v-if="invitations.length!==0">
          <b-col
            class="invites-cards"
            cols="lg-4 md-4 sm-12"
            v-for="(invite, index) in invitations"
            :key="index"
          >
            <b-card :header="$t('Meeting_name') + ': ' + invite.meeting.meetingName" align="center">
              <b-card-text>
                <b-badge class="invite-badge" href="#" variant="info">{{
                dateFormat(invite.selectedDate,invite.time,invite.duration)
                }}</b-badge>
                <div class="description-adjust" style="padding-top: 18px">
                  <p class="invite-description">
                    {{ invite.meeting.user.email }}
                  </p>
                  <p class="invite-description cut-text" v-if="invite.meeting.description">
                    {{ minimizedDescription(invite.meeting.description) }}
                    </p>
                  <p class="invite-description" v-else>{{ $t('No_Description_Yet_Available') }}</p>
                </div>
                <div class="mt-2">{{ $t('State') }} 
                <b-badge :variant="statusVariant(invite.status)" class="state">
                  {{ invite.status }}
                </b-badge></div>
              </b-card-text>
              <b-row class="button-invites mt-2">
                <b-col cols="12">
                  <b-button class="btn btn-md btn-danger"
                    @click="deleteRequest(invite._id)"
                    >
                    {{ $t('Delete') }} 
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="lg-4 md-4 sm-12">
            <h4> {{ $t('There_havent_been_any_requests_yet') }} </h4>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from 'moment';
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { mapGetters, mapActions } from "vuex";
import { Bus } from "@/utils/bus";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCard,
  BCardGroup,
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCardFooter,
  BBadge,
  BCardText,
} from "bootstrap-vue";
export default {
  data() {
    return {
      invitations : [],
    };
  },
  components: {
    BCardActions,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BContainer,
    BFormCheckboxGroup,
    BFormCheckbox,
    BBadge,
    BCardText,
  },
  beforeCreate() {
    // this.$store.dispatch("fetchmeetings");
  },
  computed: {
    statusVariant() {
      const statusColor = {
        accepted     : 'success',
        refused     : 'danger',
        pending     : 'warning',
      }
      return state => statusColor[state]
    },
  },
  created() {
    this.getInvitations()
  },
  methods: {
    getInvitations() {
        axios.get("https://backendapinodejs.timecheckit.com/api/meetingInvitation?invitationSender=" +JSON.parse(localStorage.getItem("userData"))._id)
        .then((response) => {
            this.invitations = response.data.data
        })
        .catch((error) => {
            this.errorMessage = error.message;
        });
    },
    dateFormat: function (date,time,duration) {
      const [hours, minutes] = time.split(":");
      const hour = parseInt(hours, 10);
      let period = "AM";
      if (hour >= 12) {
        period = "PM";
      }
      const times = time + " " + period
      return moment(date).format('YYYY/MM/DD') + " , " + times + " , " + duration + " min";
    },
    minimizedDescription(description) {
      const maxLength = 90;
      if (description.length <= maxLength) {
        return description;
      } else {
        return description.slice(0, maxLength) + "...";
      }
    },
    deleteRequest(id) {
      this.$swal({
        title: "Are you sure you want to delete this request permanently?",
        text: "You can't undo this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm !",
        cancelButtonText: "Decline !",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.delete(`https://backendapinodejs.timecheckit.com/api/meetingInvitation/${id}`)
          .then((response) => {
            this.getInvitations()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Request deleted`,
                icon: "UserIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error while deleting`,
                  icon: "UserIcon",
                  variant: "danger",
                },
            });
          });
        }
      });
    }
  }
}
</script>

<style>
.mettings .card .card-header {
  border-bottom: none !important;
  padding: 1rem !important;
  background: linear-gradient(
    118deg,
    #9600ff,
    rgba(115, 103, 240, 0.7)
  ) !important;
  color: white !important;
}
.invite-badge {
  margin-top: 20px;
  padding: 10px 18px;
  background-color: #756af0 !important;
}
.state {
  padding: 7px 10px;
}
</style>